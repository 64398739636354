.wedding-container {
  padding-top: 120px;
  min-height: 100vh;

  h2 {
    font-family: "AreYouOkay";
    text-align: center;
    color: $primary;
    font-size: 1.8rem;

    @media screen and (min-width: 960px) {
      font-size: 2.8rem;
    }
  }

  p {
        color: $grey-text2;
      padding: 20px;
      text-align: center;

      @media screen and (min-width: 769px) {
          padding: 3rem 16vw;
      }
  }

    label {
        font-weight: bold;
        text-align: center;
        display: block;
    }

  .row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem 0 4rem 0;
    flex-direction: column;
    text-align: center;

    @media screen and (min-width: 769px) {
      flex-direction: row;

      span {
        margin: 0;
      }
    }

    h6 {
        font-weight: bold;
    }

    span {
      font-size: 1rem;
      margin-bottom: 1rem;
      display: block;
      color: $primary;
    }
  }

  .columns {
      background-color: $secondary;
      padding: 3rem 0;
  }
}
