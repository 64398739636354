.home-slider {
  @media screen and (min-width: 1023px) {
    margin-top: 65px;
  }
}

.slick-dots.slick-thumb {
  position: absolute;
  bottom: 0;
  height: 40px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0));

  @media screen and (min-width: 900px) {
    height: 60px;
  }

  li {  
    width: 40px;
    background-color: white;
    height: 3px;

    @media screen and (min-width: 900px) {
      width: 60px;
      height: 4px;
  
      span {
        height: 4px;
      }
    }

    &.slick-active {
      background-color: $primary;
    }

    span {
      display: block;
      height: 3px;
    }
  }
}

.img-slider {
  object-fit: cover;
  max-height: 70vh;
  width: 100%;

  &.desktop {
    display: none;
  }

  &.mobile {
    display: block;
  }

  @media screen and (min-width: 900px) {
    &.desktop {
      display: block;
    }
  
    &.mobile {
      display: none;
    }
  }
}

.slick-prev {
  left: 15px;
  z-index: 1000;
}

.slick-next {
  right: 15px;  
}