.home-presentation {
  background-color: $secondary;
  padding: 5rem 1rem 5rem 1rem;
  text-align: center;
  
  @media screen and (min-width: 650px) {
    padding: 5rem;
  }

  .squares {
    @media screen and (max-width: 960px) {
      display: none;
    }

    .column {
      padding-bottom: 0 !important;
    }

    .square {
      width: 150px;
      height: 150px;
      background-color: $primary;
      margin: auto;
      transform: translateY(75px) rotate(45deg);

      .square-border {
        border: 2px solid black;
        background-color: transparent;
        width: 150px;
        height: 150px;
        transform: translateY(-20px) translateX(-20px);
      }

      &.main {
        transform: translateY(30px) rotate(45deg);

        .square-border {
          transform: translateY(-20px) translateX(-20px);
        }
      }
    }
  }

  .columns.bt {
    background-color: $secondary;
    position: relative;
    z-index: 3;

    @media screen and (min-width: 961px) {
      border-top: 2px solid $grey-text3;
    }
  }

  h5 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 2rem;
    line-height: 1.5rem;

    @media screen and (min-width: 960px) {
      font-size: 1.3rem;
    }
  }

  // span {
  //   font-size: 1.4rem;
  //   margin-bottom: 2rem;
  //   display: block;
  //   margin-top: -5px;
  // }

  p {
    padding: 0 12px;
  }
}

.home-courses {
  position: relative;
  min-height: 2130px;

  h2 {
    text-align: center;
    font-size: 1.8rem;
    color: $primary;
    margin-top: 1.2rem;

    @media screen and (min-width: 960px) {
      font-size: 2.8rem;
    }
  }

  .position {
    position: absolute;
    left: 0;
    right: 0;
    // top: -50px;
    top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;

    @media screen and (min-width: 550px) {
      padding: 0 50px;
    }

    .course-card {
      margin-bottom: 3rem;
    }
  }

  @media screen and (min-width: 348px) {
    min-height: 2440px;
  }

  @media screen and (min-width: 500px) {
    min-height: 2840px;
  }

  @media screen and (min-width: 750px) {
    min-height: 2150px;

    .position {
      padding: 0 40px;
    }
  }

  @media screen and (min-width: 960px) {
    min-height: 1460px;
  }

  @media screen and (min-width: 1200px) {
    min-height: 1180px;
  }

  @media screen and (min-width: 1390px) {
    .position {
      padding: 0 160px;
    }
  }

  @media screen and (min-width: 1630px) {
    .position {
      padding: 0 20vw;
    }
  }
}

.home-planning {
  padding-bottom: 2rem;
  padding-top: 3.5rem;

  h1 {
    text-align: center;
    font-size: 1.8rem;
    color: $primary;
    margin-bottom: 1.2rem;
    
    @media screen and (min-width: 960px) {
      font-size: 2.8rem;
    }
  }

  small {
    text-align: center;
    display: block;
    font-size: 1rem;
    color: $grey-text;
    
    a {
      color: $grey-text;
      text-decoration: underline;
    }
  }

  img {
    width: 98vw;
    margin: auto;
    display: block;
    
    @media screen and (min-width: 1100px) {
      width: 65vw;
    }
  }
}

.teachers-container {
  background-color: $secondary;
  min-height: 100vh;

  .teacher-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: 1600px) {
      padding: 0 5vw;
    }

    .teacher-card-container {
      margin: 2rem 1rem;      
    }
  }

  h2 {
    text-align: center;
    color: $primary;
    font-size: 1.8rem;

    @media screen and (min-width: 960px) {
      font-size: 2.8rem;
    }
  }

  p {
    color: black;
    text-align: center;
    margin: 1rem 0 3rem 0;
  }
}

.prices-container {
  background-image: url('../assets/prices-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 100px 0 60px 0;

  h6 {
    text-align: center;
    font-size: 2rem;
    margin-top: 2rem;
  }

  .help-text-top {
    text-align: center;
    color: #000000;
    font-size: 1.2rem;
  }

  .prices {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 0 20px 0;
  }

  .help-text {
    text-align: center;
    color: #000000;
    padding: 0 20vw 4rem 20vw;
  }
}

.home-openhouseweek {
  padding-bottom: 2rem;
  padding-top: 2rem;

  .divider {
    width: 75vw;
    height: 1px;
    display: block;
    background-color: $grey-text3;
    margin: 0 auto 3rem auto;
    opacity: .2;
  }

  h1 {
    text-align: center;
    font-size: 1.8rem;
    color: $primary;
    margin-bottom: 2.2rem;
    
    @media screen and (min-width: 960px) {
      font-size: 2.8rem;
    }
  }

  img {
    width: 98vw;
    margin: auto;
    display: block;
    
    @media screen and (min-width: 1100px) {
      width: 80vw;
    }
  }
}

.home-history {
  .home-intro {
    padding: 3rem;

    @media screen and (min-width: 1000px) {
      padding: 3rem 6rem;
    }

    .logo {
      margin: 0 auto 2rem auto;
      display: block;
    }
  
    .intro {
      text-align: center;

      .mb-2 {
        margin-bottom: 30px;
      }

      p {
        padding-bottom: 15px;
      }
  
      @media screen and (min-width: 700px) {
        padding: 0 10vw;
      }
    }
  }

  .home-container {
    background-image: url("./../assets/home_background.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    
    .home-1, .home-2, .home-3 {
      padding: 2rem 0 0 0;
  
      img {
        display: none;
      }
  
      label {
        display: block;
        color: $primary;
        padding: 6px 0;
        font-size: 1.8rem !important;
        background-image: url("./../assets/label-brush-background.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
  
      ul {
        li {
          color: $grey-text;
        }
      }
  
      label, li {
        font-family: "Lost Rock";
        font-size: 1.4rem;
        text-align: center;
      }
    }

    @media screen and (min-width: 900px) {
      display: flex;
      justify-content: center;
      padding: 1rem 0 3rem 0;

      .home-1, .home-2, .home-3 {
        flex: 1 1 0;

        img {
          display: block;
          margin: auto;
        }
      }
    }

    @media screen and (min-width: 1600px) {
      padding: 1rem 6vw 3rem 6vw;
    }
  }
}

.teacher-container {
  min-height: 100vh;
  padding-top: 65px;

  .mt-2-2 {
    margin-top: 2.2rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .columns {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .teacher-img {
    min-height: 100vh;
    background-color: #242629;
    background-position: 50% 50%;
    background-size: cover;
    padding: 0 !important;

    .header-block {
      z-index: 2;
      background: rgba(0, 0, 0, 0.4);
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 50%;
      padding: 3rem;

      span {
        background-color: #000000;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $primary;
        font-weight: 300;
        position: absolute;
        top: -20px;
        height: 40px;
        padding: 0 1rem;
        line-height: 40px;
      }

      h1 {
        color: #ffffff;
        border-bottom: 1px solid #ffffff;
        font-size: 2rem;
        padding-bottom: 1.5rem;
      }
    }
  }

  .teacher-infos {
    min-height: 100vh;
    padding: 0 0 2rem 0;

    h3 {
      font-weight: bold;
      padding: 3rem 3rem 0 3rem;
      font-size: 1.4rem;
    }

    .trophies {
      position: relative;
      background-color: $secondary;
      padding: 2rem 2.6rem 1rem 2rem;
      border-bottom: 1px solid $primary;

      span {
        padding: 0 1rem;
      }
    }

    img {
      margin: 3rem auto 1rem auto;
      display: block;
    }

    p {
      padding: 1rem 3rem;
      line-height: 1.75;
      font-weight: 300;
      font-size: 1.1rem;
    }
  }
}

.mentions {
  min-height: 100vh;
  padding-top: 65px;
  background-color: $secondary;

  .card {
    width: 75vw;
    margin: 60px auto;

    h6 {
      font-weight: 600;
      margin-top: 1.6rem;
    }
  }
}

.home-rooms {
  padding: 3rem 1rem;
  text-align: center;
  background-color: $secondary;

  @media screen and (min-width: 650px) {
    padding: 3rem 5rem;
  }
  
  h3 {
    font-size: 1.8rem;
    color: $primary;
    margin-bottom: 2.2rem;
    display: inline-block;

    @media screen and (min-width: 960px) {
      font-size: 2.8rem;
    }
  }

  p {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .teacher-container {
    .teacher-img {
      max-height: 300px;
      min-height: 0;
      height: 300px;
      background-position: 50% 50%;
      background-size: cover;
      overflow: hidden;
      padding-bottom: 66%;

      .header-block {
        display: none;
      }
    }
  }
}

.events-list {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  
  @media screen and (min-width: 960px) {
    padding: 2rem 6rem;
  }
  
  @media screen and (min-width: 1200px) {
    padding: 2rem 10rem;
  }
}
