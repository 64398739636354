input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}


input[type=checkbox] {
  -border-radius: 4px;
  height: 20px;
  width: 20px;
  background: rgba(128, 128, 128, 0.5);
  border: 1px solid $primary;
  border-radius: 3px;
}

input[type="checkbox"]:checked {
  background: white;
  margin:0px;
  position: relative;
  &:before {
    content: "✓";
    display: block;
    color: black;
    font-size: 20px;
    position: absolute;
    left: 2px;
    top: -5px;
  }
}