.price-modal {

  .modal-background {
    display: none;
  }

  &.is-active {
    .modal-background {
      display: block;
    }

  }

  .modal-content {
    text-align: center;
    background-color: white;
    width: 600px;
    padding: 2rem 0 2rem;

    .modal-header {
      font-size: 2rem;
      font-weight: 200;
      text-align: center;
      text-transform: uppercase;
    }

    .modal-header-divider {
      margin: 1rem auto;
      width: 80%;
      height: 2px;
      background-color: $primary;
    }

    .modal-image {
      margin: 2.5rem 0;

      &.is-1, &.is-2 {
        margin: 5rem 0;

        img {
          height: 50px;
        }
      }

      &.is-3, &.is-4 {
        margin: 4.5rem 0;

        img {
          height: 90px;
        }
      }

      img {
        height: 110px;
        // filter: brightness(0%);
      }
    }

    p {
      padding: 1rem 4rem;
      color: black;
      text-align: justify;

      &.infos {
        font-size: 0.8rem;
      }
    }

    .modal-formula-band {
      background-color: rgba(120, 120, 120, 0.1);
      padding: 1.5rem;
      margin-bottom: 2rem;

      h2 {
        color: $primary;
        font-size: 30px;
      }
    }

    .button {
      width: 80%;
      height: 70px;
      color: black;
      font-size: 30px;
      border-radius: 0;
      font-weight: 700;
      border: solid 2px black;
      text-transform: uppercase;
      transition-duration: 0.2s;
      box-shadow: rgba(0, 0, 0, 0.17) 5px 5px 6px;

      &:hover {
        color: $primary;
      }
    }
  }

  .modal-prices-container {
    display: flex;
    margin-bottom: 2rem;
    padding: 1rem 4rem;
    color: black;
    text-align: justify;

    & > div {
      flex: 1;

      &:first-of-type {
        margin-right: 0.5rem;
      }

      &:last-of-type {
        margin-left: 0.5rem;
      }

      h3 {
        font-weight: 600;
        text-align: center;
        margin-bottom: 0.3rem;
      }

      span {
        text-align: center;
        padding: .5rem;
        display: block;
        border: 2px solid $primary;
      }
    }
  }

}

@media screen and (max-width: 768px) {

  .price-modal {
    .modal-content {
      width: 100%;

      .modal-header {
        font-size: 1.3rem;
      }

      .modal-image {

        margin: 1.5rem 0;

        img {
          height: 60px;
        }
      }


      .modal-formula-band {
        margin-bottom: 1rem;

        h2 {
          font-size: 1.5rem;
        }
      }

      p {
        font-size: 0.9rem;
        padding: 1rem 1rem;
      }

      .button {
        font-size: 1.2rem;
        height: 45px;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .modal-close {
    top: 30px;
    right: 50%;
    transform: translateX(50%);
  }

}
