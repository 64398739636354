$background-color: rgba(23, 23, 23, 0.70);

section.planning {
  color: white;
  margin-top: $navbar-height;
  //height: calc(100vh - 65px);
  background-image: url("./../assets/dances-background-white.jpg");
  padding-bottom: 15vh;

  .planning-header {
    height: 10%;
    padding-top: 20px;

    .button {
      z-index: 10;
      position: fixed;
      border-radius: 0;
      font-weight: 400;
      padding: 1.5rem 8vw;
      text-transform: uppercase;
      transition-duration: 0.2s;
      border: solid 4px $primary;

      &:hover {
        color: $primary;
        border: solid 4px white;
        background-color: $background-color;
      }
    }

    .print-planning {
      color: $primary;
      border: solid 3px white;
      margin-top: 1rem;
      padding: .5rem 1.5rem;
      background-color: rgba(255, 255, 255, 0.1);

      &:hover {
        background-color: white;
        font-weight: bold;
      }
    }

    .planning-header-content {
      color: white;
      padding: 0 5%;
      text-align: center;

      h6 {
        text-transform: uppercase;
      }
    }

    .planning-filter {
      display: flex;
      margin: 10px 50px;
      position: relative;
      align-items: flex-end;
      flex-direction: column;

      h4 {
        left: 1rem;
        top: -0.5rem;
        color: white;
        font-size: 1rem;
        font-weight: 200;
        position: absolute;
      }

      ul {
        width: auto;
        text-align: left;
        padding: 25px 40px 25px 20px;
        background-color: $background-color;

        li {
          color: white;
          width: 150px;
          font-size: 0.9rem;
          position: relative;
          text-align: center;
          display: inline-block;

          input {
            margin-right: 0.25rem;
          }

          span {
            top: 0;
            width: 150px;
            text-align: left;
            position: absolute;
            text-transform: capitalize;
          }

          .checkbox {
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }

  .planning-room-list {
    flex: 2;
    div {
      font-size: 0.8rem;
      color: $primary;
      height: 80px;
      margin: 10px auto;
    }
  }

  .times-container {
    & > .aligner {
      & > h2 {
        flex: 3;
      }
    }
  }

  .planning-content {
    width: 95%;
    margin: 0 auto;
    padding: 0 0 250px;
    background-color: $background-color;

    .column {
      text-align: center;
    }

    .courses-header {
      height: 10%;
    }

    .timetable {
      h2 {
        height: 260px;
        margin: 10px;
        font-weight: 200;
        font-size: 1.2rem;

      }
    }

    .mobile-time {
      display: none;
      color: $primary;
      margin-top: 5px;
    }


    .divider {
      height: 1px;
      width: 80%;
      margin: 0 auto;
      background-color: $primary;
    }

    .courses-container {
      border-right: solid 1px $primary;

      .planning-course {
        width: 80%;
        height: 80px;
        margin: 10px auto;
        border-radius: 5px;
        padding: 0.3rem 2%;
        position: relative;
        transition-duration: 0.2s;
        border: solid 1px rgba(255, 255, 255, 0.3);
        background-color: rgba(128, 128, 128, 0.05);

        .course-room {
          $course-room-size: 1.4em;
          top: 0.5em;
          right: 0.5em;
          color: $primary;
          font-size: 0.8em;
          position: absolute;
          border-radius: 50%;
          border: 1px solid $primary;
          height: $course-room-size;
          width: $course-room-size;
        }

        &.no-courses {
          border: none;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: rgba(128, 128, 128, 0.7);
        }

        &.active {
          color: black;
          border: solid 1px black;
          background-color: rgba(255, 255, 255, 0.9);

          .course-room {
            color: black;
          }

          &:hover {
            background-color: $primary;
          }
        }

        .aligner {
          flex-direction: column;

          div {
            width: 100%;
            text-align: left;
            text-align: center;
            margin-top: 0.25rem;

            &.course-name {
              font-weight: 700;
            }
          }
        }
      }

      &.has-no-border {
        border: none;
      }
    }
  }
}

@media screen and (max-width: 1620px) {
  .planning-course {
    height: 105px !important;
  }

  .timetable {
    h2 {
      height: 334px !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .timetable {
    display: none;
  }

  .mobile-time {
    display: block !important;
  }

  .course-name {
    font-size: 12px;
  }

  .planning-course {
    height: 85px !important;
  }

  .course-teachers {
    font-size: 12px;
  }

  .divider {
    display: none;
  }
}

@media screen and (max-width: 768px) {

  .planning-footer {
    font-size: 1rem !important;

    p {
      text-align: justify;
      padding: 0 5vw;
    }
  }

  section.planning {
    .planning-header {
      height: auto !important;
    }

    .planning-filter {
      margin: 0 !important;
      align-items: center !important;

      h4.title {
        display: none;
      }

      ul {
        width: auto !important;
      }
    }

    .planning-content {
      height: auto !important;
    }

    .planning-course {
      height: 60px !important;

      &.no-courses {
        display: none;
      }
    }

    .courses-container {
      border-right: none !important;
    }

  }

  .button {
    width: 100% !important;
  }
}

.planning-footer {
  color: #ffffff;
  padding: 2vh 0;
  font-weight: 300;
  margin-top: 3rem;
  font-size: 1.25rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.40);
}
