// import using ~
@import "~bulma/bulma.sass";
// @import "~font-awesome/scss/font-awesome.scss";
// @import "~slick-carousel/slick/slick.scss"; 
// @import "~slick-carousel/slick/slick-theme.scss";

// import starting from the src/ folder
@import "variables.scss";
@import "footer.scss";
@import "slider.scss";
@import "navbar.scss";
@import "sections.scss";
@import "contact.scss";
@import "cards.scss";
@import "planning.scss";
@import "checkbox.scss";
@import "price-modal.scss";
@import "events.scss";
@import "wedding.scss";

@font-face {
  font-family: "AreYouOkay";
  src: url(../assets/fonts/AreYouOkay.ttf);
  src: url(../assets/fonts/AreYouOkay.otf);
}

body {
  margin: 0;
}

.main-font {
  font-family: "AreYouOkay";
}

.primary-text {
  color: $primary;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-modal {
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid rgba(112, 112, 112, .3);
  padding: 30px 6rem 80px 6rem;
  text-align: center;
  position: relative;

  h2 {
    font-family: "Lost Rock";
    color: $primary;
    font-size: 2.6rem;
  }

  h6 {
    margin-top: 2rem;
  }

  p {
    color: $grey-text2;
    font-weight: 200;
    margin: 3rem auto 1rem auto;
  }
  
  .pdf {
    background-color: $primary;
    color: #ffffff;
    text-transform: uppercase;
    border: 1px solid #787878;
    padding: .5rem 4rem;
    font-weight: 200;
    font-size: 1.2rem;
    letter-spacing: 1px;

    a {
      color: #ffffff; 
    }
  }

  span {
    display: block;
    color: $primary;
    font-size: .8rem;
  }

  .is-large.modal-close {
    position: absolute !important;
    z-index: 9999 !important;

    &:before, &:after {
      background-color: #000000 !important;
    }
  }
}

.wedding-link {
  position: fixed;
  bottom: 0;
  right: 30px;
  background-color: $primary;
  padding: 8px 12px;
  text-align: center;
  z-index: 5;

  &:hover {
    cursor: pointer;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
  }

  a {
    color: black;
    font-weight: bold;
  }
}