section.events-section {
  margin-top: $navbar-height;
  min-height: calc(100vh - 65px);

  &::before {
    top: $navbar-height;
    left: 0;
    content: "";
    z-index: -1;
    position: absolute;
    display : inline-block;
    border-top : calc(100vh - 65px) solid $primary;
    border-right : 100vw solid transparent;
  }

  h2 {
    padding-top: 5vh;
    text-align: center;
  }

  .divider {
    width: 90%;
    height: 3px;
    background-color: #363636;
    margin: 0 auto;
  }

  .event-item {
    transition: box-shadow .35s;

    &:hover {
      box-shadow: 0 0 10px;
    }

    img {
      object-fit: cover;
    }
  }
}