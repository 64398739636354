nav {
  position: fixed !important;
  top: 0;
  width: 100vw;
}

h1.main-font {
  font-size: 2rem;
}

.navbar {
  height: $navbar-height;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));

  .navbar-menu.is-active {
    background-color: black !important;
    position: relative !important;
    z-index: 3000 !important;
  }
}

.navbar-brand {
  padding-right: 1rem;
}

a.navbar-item {
  color: white;
  font-weight: 600;
  padding: 0.5rem 1.25rem;
  transition-duration: 0.2s;
  font-size: 1.2rem;
  text-transform: uppercase;

  &:hover {
    color: $primary;
    background-color: transparent;
  }

  &:last-of-type {
    margin-right: 3vw;
  }
}

@media screen and (max-width: 1023px) {
  .home-page nav {
    position: inherit !important;
  }

  .navbar {
    background-color: black !important;
  }
}
