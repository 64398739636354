footer {
  z-index: 5;
  position: relative;
  background-color: #000000;
  padding: 3rem 1.5rem 1rem 1.5rem;

  .columns {
    flex-wrap: wrap;
    
    @media screen and (min-width: 1000px) {
      padding: 0 80px;
    }

    @media screen and (max-width: 1020px) {
      text-align: center;
    }
  }
      
  h6 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: .5rem;
  }

  p {
    color: rgba(255, 255, 255, .65);
  }

  a {
    color: rgba(255, 255, 255, .65);
    transition-duration: 0.2s;

    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }
    
  ul {
    li {
      color: rgba(255, 255, 255, .65);
    }
  }

  i {
    display: block;
    font-size: 2rem;
    margin: .5rem 0;
    transition-duration: 0.2s;

    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }

  .subfooter {
    display: flex;
    padding-top: .6rem;
    justify-content: space-between;
    border-top: 1px solid $secondary;
    background-color: #000000;
    color: $grey-text;
    
    :first-child {
      text-transform: uppercase;
      text-decoration: none;
      color: $grey-text;
    }
  }

  .contact {
    p {
      margin-bottom: 8px;
    }

    button {
      &:hover {
        cursor: pointer;
        background-color: $primary;
        color: #000000;
      }
  
      margin: 8px 0;
      border: 1px solid $primary;
      background: #000000;
      color: $primary;
      font-size: 1rem;
      transition-duration: 0.2s;
    }
  }

  .follow {
    a {
      margin-right: 8px;
    }

    p {
      font-style: italic;
      font-size: .9rem;
    }
  }
}